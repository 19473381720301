import { LocationApi } from "@/api"
import { CreateGMBAccessType } from "@/api/location"
import env from "@/config/env"
import { socket } from "@/config/socket"
import { ResellerService } from "@/services"
import GoogleStore from "@/store/google"
import Cookies from "js-cookie"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import useUtilsService from "../useBase64"
import useNotification from "../useNotification"
import useSelectAccount from "../useSelectAccount"

const useLogic = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const {
        companyId,
        account_id,
        multiple_accounts,
        callbackUrl,
        user_id,
        gbp_account,
        selected_businesses,
        update,
        getAll,
    } = GoogleStore()

    const [countBusinesses, setCountBusinesses] = useState<number>(0)
    const [isSync, setIsSync] = useState<boolean>(false)
    const { decode64AndParse } = useUtilsService()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const { openGoogleAccount } = useSelectAccount()

    const updateLocationStore = useCallback(
        (data) => {
            update({
                companyId: data?.companyId ?? "",
                user_id: data?.user_id ?? "",
                callbackUrl: data?.cb ?? "",
                multiple_accounts: data?.multiple_accounts ?? false,
                add_account: data?.add_account ?? false,
                page_loading: false,
            })
        },
        [update]
    )

    const getParams = useCallback(() => {
        const state = searchParams.get("state") ?? ""
        const code = searchParams.get("code") ?? ""
        if (state && !companyId) {
            const data = decode64AndParse(searchParams.get("state") ?? "")
            if (data) {
                const add_account = data?.add_account ?? false
                if (data?.add_account) delete data?.add_account
                Cookies.set("google_metadata", JSON.stringify(data))
                if (data?.gmb_access_id) {
                    Cookies.set("gmb_access_id", data?.gmb_access_id)
                } else {
                    Cookies.remove("gmb_access_id")
                }

                return {
                    ...data,
                    add_account,
                    code,
                }
            } else {
                return null
            }
        }
        if (Cookies.get("google_metadata")) {
            return {
                ...JSON.parse(Cookies.get("google_metadata")),
                gmb_access_id: Cookies.get("gmb_access_id") ?? "",
            }
        }
        return getAll()
    }, [searchParams, getAll])

    const createGmbAccess = useCallback(
        async (data: CreateGMBAccessType) => {
            update({
                is_fetching: true,
            })
            const resp = await LocationApi.createGmbAccess(data)
            if (!resp?.error) {
                update({
                    gmb_access_id: resp?.gmbAccessId,
                    companyId: data?.companyId,
                    add_account: false,
                    is_fetching: false,
                })

                resp.gmbAccessId && Cookies.set("gmb_access_id", resp?.gmbAccessId ?? "")
            } else if (
                resp?.error?.error === "invalid_grant" ||
                resp?.error === "INVALID_SCOPE" ||
                resp?.error?.error === "INVALID_SCOPE" ||
                resp?.error?.error === "NO_EMAIL_FOUND" ||
                resp?.error === "NO_EMAIL_FOUND"
            ) {
                update({
                    missing_scope: true,
                    is_fetching: false,
                })
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                update({
                    is_fetching: false,
                })
            }
        },
        [update, notif]
    )

    const fetchData = useCallback(async () => {
        const params = getParams()
        // Si ni le code ni la companyId n'est présente dans les paramètres
        if (!params?.code && !params?.companyId) {
            // Accès refusée
            navigate("/error")
            return
        }
        // Si on a un company id mais pas de gmb_access_id ( store )
        // Il faut le récupérer

        // Si on a un code (google )
        // On peut l'utiliser pour récupérer un access ID via l'api create gmb access
        // Si on a une demande d'add_account ou que si on n'est pas en multiple
        // createGmbAccess
        // Stocker le gmb_access_id dans le store

        // Si on n'a pas de demande d'add_account et qu'on est en multiple
        // il faut récupérer la liste des gbp-accounts à partir de la compagnie id

        // Si on n'est pas en multiple
        // il faut récupérer les accounts directement à partir d'un gmb_access_id (celui stocké dans le store )
        // Idéalement un useEffect dans le composant form-filter avec comme dépendance le gmb_access_id et les params?.multiple

        if (params?.companyId && !params?.gmb_access_id) {
            if (params?.code) {
                if (!!params?.add_account || !params?.multiple_accounts) {
                    await createGmbAccess({
                        code: params.code,
                        companyId: params.companyId,
                        redirecturi: env.APP_OAUTH_REDIRECT_URL,
                        isMultiples: !!params?.multiple_accounts,
                    })
                }
            }

            // Sinon on est déjà censé avoir un gmb_access_id aenregistré dans les cookies à ce stade
        }
        updateLocationStore(params)

        searchParams.forEach((_, key) => searchParams.delete(key))
        setSearchParams(searchParams)
    }, [getParams, navigate, setSearchParams, updateLocationStore])

    const handleToggleCheckbox = useCallback(
        (value: string) => {
            if (selected_businesses.indexOf(value) > -1) {
                update({
                    selected_businesses: selected_businesses.filter((item) => item !== value),
                })
            } else {
                update({
                    selected_businesses: [...selected_businesses, value],
                })
            }
        },
        [selected_businesses, update]
    )

    const handleReturn = (created = 0) => {
        Cookies.remove("google_metadata")
        Cookies.remove("facebook_metadata")
        Cookies.remove("gmb_access_id")
        ResellerService.clearReseller()
        window.location.href = `${callbackUrl}${created ? "/?auth_type=google&created=" + created : ""}`
    }

    const handlePrevious = useCallback(
        (add_account = false) => {
            openGoogleAccount({
                companyId,
                user_id,
                cb: callbackUrl,
                access_id: "",
                multiple_accounts: multiple_accounts,
                add_account,
            })
        },
        [companyId, user_id, callbackUrl, multiple_accounts]
    )

    const handleSync = useCallback(async () => {
        setIsSync(true)
        socket.emit("connectLocation", {
            companyId: companyId,
            gmbId: gbp_account,
            userId: user_id,
        })

        socket.on("locationStatus", (res) => {
            setCountBusinesses(res.nbPassed)
            if (res.nbPassed === res.totalCount) {
                handleReturn(selected_businesses.length)
            }
        })
        await LocationApi.synchronize({
            company_id: companyId,
            gmb_access_id: gbp_account,
            locations_name: selected_businesses,
            user_id,
            account_id,
        })
    }, [selected_businesses, companyId, user_id, account_id, gbp_account])

    const handleCancel = () => {
        handleReturn()
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return {
        countBusinesses,
        selected_businesses,
        isSync,
        params: getParams(),
        handleSync,
        handleCancel,
        handleToggleCheckbox,
        handlePrevious,
        createGmbAccess,
        fetchData,
    }
}

export default useLogic
