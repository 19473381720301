import Consent from "@/assets/consent.png"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { Box, Button, Modal, Paper, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton/IconButton"
import { useTranslation } from "react-i18next"
import { modalStyle } from "./style"

const ConsentModal = ({
    open,

    onClose,
    onRetry,
}: {
    open: boolean

    onClose: () => void
    onRetry: () => void
}) => {
    const { t } = useTranslation()
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-remove"
            disableRestoreFocus
        >
            <Paper elevation={6} sx={modalStyle}>
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" data-testid="title" variant="h2" data-e2e="add-logo-title">
                        {t("BUSINESSES.OAUTH.GRANT_ACCESS")}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose} className="btn-x" data-testid="title-close">
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <img src={Consent} alt="consent image" />
                <Box className="action-wrapper">
                    <Button onClick={onRetry} variant="contained">
                        {t("BUSINESSES.OAUTH.RETRY")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}

export default ConsentModal
