import { create } from "zustand"

export interface GoogleAccountsType {
    accountName?: string
    name?: string
    type?: string
}

export interface LocationAddressType {
    regionCode?: string
    postalCode?: string
    languageCode?: string
    administrativeArea?: string
    addressLines?: string[]
    locality?: string
}
export interface LocationType {
    name: string
    locationName: string
    is_connected: boolean
    address?: LocationAddressType
}

export interface GBPAccountType {
    email: string
    access_id: string
}
export interface IGoogleStore {
    gmb_access_id?: string
    companyId: string
    user_id: string
    accounts: GoogleAccountsType[]
    multiple_accounts: boolean
    locations: LocationType[] | null
    callbackUrl: string
    location_name?: string
    code?: string
    gbp_accounts?: GBPAccountType[]
    selected_businesses?: string[]
    add_account?: boolean
    gbp_account: string
    missing_scope?: boolean
    is_fetching?: boolean
    account_id: string
    filter: string
    page_loading: boolean
    missing_token: boolean
    compte_name: string
}

export interface IGoogleSetter {
    update: (update: Partial<IGoogleStore>) => void
    getAll: () => IGoogleStore
}
// const google_metadata = Cookies.get("google_metadata") ? JSON.parse(Cookies.get("google_metadata")) : {}
const initalState = {
    gmb_access_id: "",
    companyId: "",
    user_id: "",
    callbackUrl: "",
    multiple_accounts: false,
    add_account: false,
    location_name: "",
    selected_businesses: [],
    code: "",
    accounts: [],
    locations: null,
    gbp_accounts: [],
    gbp_account: "",
    missing_scope: false,
    is_fetching: false,
    account_id: "",
    filter: "",
    page_loading: true,
    missing_token: false,
    compte_name: "",
}

const GoogleStore = create<IGoogleStore & IGoogleSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    getAll: () => initalState,
}))

export default GoogleStore
