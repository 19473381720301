import { Button } from "@mui/material"
import Icon from "../../Icon"

const BaseOption = ({ option, onItemClicked }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                width: "100%",
            }}
        >
            {option?.icon && <Icon name={option?.icon} />}
            <span>{option?.label}</span>
        </Button>
    )
}

export default BaseOption
