import { ApiService } from "@/api"
import useUtilsService from "@/hooks/useBase64"
import useFormater from "@/hooks/useFormater"
import Cookies from "js-cookie"
import { useEffect, useMemo } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

const Home = () => {
    const { queryStringToObject } = useFormater()
    const { hash } = useLocation()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { decode64AndParse } = useUtilsService()

    const hashParams = useMemo(() => {
        if (hash) {
            console.log(hash, "hash")
            const data = queryStringToObject(hash.replace("#", ""))
            return data?.state ? decode64AndParse(data?.state) : {}
        }
        if (Cookies.get("facebook_metadata")) {
            return Cookies.get("facebook_metadata") ? JSON.parse(Cookies.get("facebook_metadata")) : {}
        }
        return null
    }, [hash, queryStringToObject])

    const queryParams = useMemo(() => {
        if (searchParams && searchParams.get("state")) {
            const data = decode64AndParse(searchParams.get("state"))
            return data
        }
        if (Cookies.get("google_metadata")) {
            return Cookies.get("google_metadata") ? JSON.parse(Cookies.get("google_metadata")) : {}
        }
        return null
    }, [searchParams])

    useEffect(() => {
        const facebookError = searchParams.get("error")
        if (facebookError && facebookError === "access_denied") {
            navigate(`/auth_facebook/${searchParams.get("state")}`)
            return
        }

        if (hashParams) {
            if (hashParams?.bearer) {
                ApiService.setToken(hashParams?.bearer, "")
            }
            if (hashParams?.auth_type === "instagram") {
                navigate(`/auth_instagram/${hash}`)
            } else {
                navigate(`/auth_facebook/${hash}`)
            }
            return
        }
        if (queryParams) {
            if (queryParams?.bearer) {
                ApiService.setToken(queryParams?.bearer, "")
            }
            if (queryParams?.locationName) {
                navigate(`/redirect?${searchParams}`)
            } else if (queryParams?.multiple_accounts) {
                navigate(`/auth_google?${searchParams}`)
            } else {
                navigate(`/auth_google?${searchParams}`)
            }
            return
        }
        navigate("/error")
    }, [hashParams, hash, queryParams, searchParams, navigate])

    return <></>
}

export default Home
