// declare const APP_VERSION: string
const ENV = import.meta.env

const env = {
    ENV: ENV.VITE_ENV,
    APP_VERSION: ENV.VITE_VERSION,

    BASE_URI: ENV.VITE_BASE_URI,
    GW_BASE_URL: ENV.VITE_GW_BASE_URL,
    APPBACK_API_PATH: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_APPBACK_API_PATH}`,
    DISPATCHR_API_PATH: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_DISPATCHR_API_PATH}`,
    WEBSOCKET_HANDSHAKE_PATH: `${ENV.VITE_WEBSOCKET_HANDSHAKE_PATH}`,
    APP_OAUTH_REDIRECT_URL: ENV.VITE_APP_OAUTH_REDIRECT_URL,
    COOKIE_NAME: ENV.COOKIE_NAME,
    INSTAGRAM_SCOPE: `${ENV.VITE_FACEBOOK_SCOPE},${ENV.VITE_INSTAGRAM_SCOPE}`,
    FACEBOOK_SCOPE: ENV.VITE_FACEBOOK_SCOPE,
    SCOPE_GOOGLE_BUSINESS: ENV.VITE_GBP_SCOPE,

    MUIX_LICENSE: ENV.VITE_MUIX_API_KEY,
    GMB_CLIENT_ID: ENV.VITE_GBP_CLIENT_ID,
    FACEBOOK_APP_ID: ENV.VITE_FACEBOOK_APP_ID,

    SENTRY_DSN: ENV.VITE_SENTRY_DSN,
    SENTRY_TRACE_SAMPLE_RATE: ENV.VITE_SENTRY_TRACE_SAMPLE_RATE,
}

export default env
