import { ApiService, LocationApi } from "@/api"
import { CreateGMBAccessType } from "@/api/location"
import env from "@/config/env"
import { socket } from "@/config/socket"
import GoogleStore from "@/store/google"
import Cookies from "js-cookie"
import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import useUtilsService from "../useBase64"
import useSelectAccount from "../useSelectAccount"

const useLogic = () => {
    const [searchParams] = useSearchParams()
    const { decode64AndParse } = useUtilsService()
    const { location_name } = GoogleStore()
    const [showModal, setShowModal] = useState(false)
    const { openGoogleAccount } = useSelectAccount()
    const [googlState, setGoogleState] = useState(null)

    const createGmbAccess = useCallback(
        async (data: CreateGMBAccessType) => {
            setShowModal(false)
            const resp = await ApiService.post(`/_entity/gmb_access`, {
                companyId: data?.companyId,
                redirecturi: data?.redirecturi,
                gmbAccessId: data?.gmbAccessId,
                code: data?.code,
            })

            if (
                resp?.error?.error === "invalid_grant" ||
                resp?.error === "INVALID_SCOPE" ||
                resp?.error?.error === "INVALID_SCOPE" ||
                resp?.error?.error === "NO_EMAIL_FOUND" ||
                resp?.error === "NO_EMAIL_FOUND"
            ) {
                setShowModal(true)
                return
            }
            // if (!resp?.gmbAccessId) {
            //     window.location.href = data?.callbackUrl + "/?auth_type=google&failed=" + data?.locationName
            //     return
            // }
            socket.emit("connectLocation", {
                companyId: data?.companyId,
                gmbId: resp?.gmbAccessId,
                userId: data?.user_id,
            })

            socket.on("locationStatus", (res) => {
                if (res.nbPassed === res.totalCount) {
                    window.location.href = data?.callbackUrl + "/?auth_type=google&updated=" + data?.locationName
                } else {
                    window.location.href = data?.callbackUrl + "/?auth_type=google&failed=" + data?.locationName
                }
            })
            setTimeout(async () => {
                await LocationApi.synchronize({
                    company_id: data?.companyId,
                    gmb_access_id: resp?.gmbAccessId,
                    locations_name: [data?.locationName],
                    user_id: data?.user_id,
                    account_id: "",
                })
            }, 300)
        },
        [setShowModal]
    )

    const retry = useCallback(() => {
        if (googlState) {
            openGoogleAccount({
                companyId: googlState?.companyId,
                user_id: googlState?.user_id,
                access_id: googlState?.gmb_access_id,
                cb: googlState?.cb,
                yext_id: googlState?.yext_id ?? "",
                locationName: googlState?.locationName,
                bearer: ApiService.getToken()?.token,
            })
        }
    }, [openGoogleAccount, googlState])

    useEffect(() => {
        const code = searchParams.get("code") || ""
        const state = searchParams.get("state") || ""
        let inf = null

        if (state) {
            inf = decode64AndParse(state)
            if (!code) {
                window.location.href = inf?.cb + "/?auth_type=google&failed=" + inf?.locationName
                return
            }

            Cookies.set("google_metadata", JSON.stringify(inf))
            setGoogleState(inf)
            createGmbAccess({
                companyId: inf?.companyId || "",
                user_id: inf?.user_id || "",
                callbackUrl: inf?.cb || "",
                gmbAccessId: inf?.gmb_access_id || "",
                locationName: inf?.locationName || "",
                code,
                redirecturi: env.APP_OAUTH_REDIRECT_URL,
            })
        }
    }, [searchParams])

    return {
        locationName: location_name,
        showModal,
        setShowModal,
        retry,
    }
}

export default useLogic
