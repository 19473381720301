import { makeStyles } from "tss-react/mui"

export const updateButtonSx = {
    display: "flex",
    gap: 1,
    minWidth: "fit-content",
    justifyContent: "flex-start",
    marginLeft: "auto",
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: "flex",
        padding: 8,
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 5.92,
        boxSizing: "border-box",
        minHeight: "100%",
        height: "100%",
        "@media (min-width: 768px)": {
            padding: "8px 24px",
        },
        "&.facebook": {
            "@media (min-width: 768px)": {
                justifyContent: "flex-start",
            },
            "& .MuiListItemButton-root": {
                justifyContent: "space-between",
            },
        },
        "& .logo-wrapper": {
            width: 200,
            height: 80,
            margin: "0 auto",
            "& img": {
                objectFit: "contain",
                width: "100%",
                height: "100%",
            },
        },
        "& .wrapper": {
            display: "flex",
            flexDirection: "column",
            gap: 16,
            height: "auto",
            "&.list-empty": {
                justifyContent: "space-between",
            },
            "& .list-wrapper": {
                display: "flex",
                flexDirection: "column",
                gap: 8,

                width: "100%",
                boxSizing: "border-box",
                minHeight: "calc(100vh - 150px)",
                height: "100%",

                "& .search-limit, & .MuiListItemText-secondary": {
                    color: theme.palette.action.active,
                },
                "& .filter-inputs": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    "@media (min-width: 768px)": {
                        flexDirection: "row",
                        gap: 16,
                    },
                },
                "& .MuiList-root": {
                    padding: 0,
                    gap: 10,
                    display: "flex",
                    flexDirection: "column",
                    "@media (min-width: 768px)": {
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                    },
                    "& .MuiListItemButton-root": {
                        padding: 16,
                        borderRadius: 8,
                        boxShadow: "0px 1px 1px 0px rgba(0, 4, 31, 0.08), 0px 1px 4px 0px rgba(8, 0, 31, 0.12)",
                        boxSizing: "border-box",
                        width: "100%",
                        "&.Mui-disabled": {
                            opacity: 1,
                            backgroundColor: "rgba(255, 255, 255, .42)",
                            "& *": {
                                color: theme.palette.text.disabled,
                            },
                        },
                        "& .MuiListItemText-secondary": {
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                        },
                        "& .MuiCheckbox-root": {
                            margin: 0,
                        },
                        "& .MuiListItemIcon-root": {
                            minWidth: 0,
                        },
                        "&.selected": {
                            backgroundColor: theme.palette.secondary.light,
                        },
                    },
                },
                "& .radio-element": {
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: 8,
                    boxShadow: "0px 1px 1px 0px rgba(0, 4, 31, 0.08), 0px 1px 4px 0px rgba(8, 0, 31, 0.12)",
                    boxSizing: "border-box",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#fff",
                    height: "fit-content",
                    "& .MuiFormControlLabel-root": {
                        padding: 16,
                    },
                    "& svg": {
                        paddingRight: "16px",
                    },
                    "& .Mui-disabled": {
                        opacity: 1,
                        backgroundColor: "rgba(255, 255, 255, .42)",
                        "& *": {
                            color: theme.palette.text.disabled,
                            fill: theme.palette.text.disabled,
                        },
                        "&:hover": {
                            cursor: "not-allowed",
                        },
                    },
                },
                "& .pagination": {
                    display: "flex",
                    justifyContent: "flex-end",
                    "& .MuiToolbar-root": {
                        gap: 26,
                    },
                    "& .table-pagination-actions-wrapper": {
                        display: "flex",
                        "& button": {
                            padding: 12,
                        },
                    },
                },
            },
            "& span.selected-length": { textAlign: "right", color: theme.palette.action.active, marginBottom: 16 },
        },
    },
}))

export default useStyles

export const formControlSx = { "@media (min-width: 768px)": { width: 240 } }
