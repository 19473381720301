import { Typography } from "@mui/material"

const BaseOption = ({ option, onItemClicked }) => {
    return (
        <>
            {option.prefix && <Typography component={"span"}>{option.prefix}</Typography>}
            <span>{option.label}</span>
        </>
    )
}

export default BaseOption
