import { ApiService, DispatchrService } from ".."
export interface CreateGMBAccessType {
    code?: string
    companyId?: string
    redirecturi: string
    user_id?: string
    gmbAccessId?: string
    callbackUrl?: string
    locationName?: string
    isMultiples?: boolean
}
class LocationApi {
    createGmbAccess: any = async (data: CreateGMBAccessType) => {
        return await ApiService.post(`/_entity/gmb_access`, data)
    }

    fetchLocations: any = async ({
        companyId,
        filter = "",
        gmb_access_id = "",
        account_id = "",
    }: {
        companyId: string
        filter: string
        gmb_access_id: string
        account_id?: string
    }) => {
        const account = account_id ? `&account_id=${account_id}` : ""
        return await DispatchrService.get(
            `/_fetch/account.location?company_id=${companyId}&filter=${filter}&access_id=${gmb_access_id}&page_size=100${account}`
        )
    }

    hasAlreadyAccess: any = async (companyId: string) => {
        return await DispatchrService.get(`/_entity/gmb_access?companyId=${companyId}`)
    }

    updateGmbAccess: any = async ({ companyId, code, type }: { companyId: string; code: string; type: string }) => {
        const body = {
            code,
            companyId,
            redirecturi: "postmessage",
            type,
        }
        return await DispatchrService.put(`/_entity/gmb_access/${companyId}${type ? "?type=messaging" : ""}`, body)
    }

    fetchAccounts: any = async ({ companyId, access_id }: { companyId: string; access_id: string }) => {
        return await DispatchrService.get(`/accounts?companyId=${companyId}&access_id=${access_id}`)
    }

    synchronize: any = async ({
        company_id,
        gmb_access_id,
        locations_name,
        user_id,
        account_id = "",
        yext_id = null,
    }: {
        company_id: string
        gmb_access_id: string
        locations_name: string[]
        user_id: string
        account_id: string
        yext_id: string
    }) => {
        const body: any = {
            company_id,
            gmb_access_id,
            locations_name,
            user_id,
        }
        if (account_id) {
            body["account_id"] = account_id
        }
        if (yext_id) {
            body["yext_id"] = yext_id
        }
        return await DispatchrService.post(`/_synchronize_down/location`, body)
    }

    getGbpAccount: any = async (company_uid: string) => {
        return await ApiService.get(`/gbp-account?company_uid=${company_uid}`)
    }
}

export default new LocationApi()
