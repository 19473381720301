import { FacebookApi } from "@/api"
import useFormater from "@/hooks/useFormater"
import useNotification from "@/hooks/useNotification"
import { ResellerService } from "@/services"
import FacebookStore from "@/store/facebook"
import { removeHashUrl } from "@/utils"
import Cookies from "js-cookie"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useUtilsService from "../useBase64"
import useSelectAccount from "../useSelectAccount"
const useLogic = () => {
    const { hash } = useLocation()
    const navigate = useNavigate()
    const { pages, update } = FacebookStore()
    const [isLoading, setIsLoading] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [selectedPage, setSelectedPage] = useState(null)
    const { queryStringToObject } = useFormater()
    const { decode64AndParse } = useUtilsService()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const { openFacebookAccount, openInstagramAndFacebookAccount } = useSelectAccount()

    const params = useMemo(() => {
        if (hash) {
            const data = queryStringToObject(hash.replace("#", ""))
            let state = null
            if (data?.state) {
                state = decode64AndParse(data?.state)
                data["state"] = state
            }
            return data
        }
        if (Cookies.get("facebook_metadata")) {
            return JSON.parse(Cookies.get("facebook_metadata"))
        }
        return null
    }, [hash])

    const fetchPages = useCallback(
        async (filter = null) => {
            const auth_type = params?.state?.auth_type ?? "facebook"
            setIsFetching(true)

            const resFetchPages =
                auth_type === "facebook"
                    ? await FacebookApi.fetchPages({
                          company_id: params?.state?.companyId,
                          location_id: params?.state?.location_id,
                      })
                    : await FacebookApi.fetchInstaPages({
                          company_uid: params?.state?.companyId,
                          ...(filter ? { q: filter } : {}),
                      })
            if (resFetchPages?.length > 0) {
                update({
                    pages: resFetchPages,
                })
            }
            if (window.location.hash) {
                removeHashUrl()
            }

            if (resFetchPages?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
            setIsFetching(false)
        },
        [params]
    )

    const checkFacebookAccess = useCallback(
        async (filter = null) => {
            setIsFetching(true)
            const auth_type = params?.state?.auth_type ?? "facebook"
            if (params) {
                if (params?.access_token) {
                    if (auth_type === "facebook") {
                        await FacebookApi.createFacebookAccess({
                            company_id: params?.state?.companyId,
                            access_token: params?.access_token,
                            expires_in: params?.expires_in,
                            location_id: params?.state?.location_id,
                        })
                    } else if (auth_type === "instagram") {
                        await FacebookApi.createIgAccess({
                            company_id: params?.state?.companyId,
                            access_token: params?.access_token,
                        })
                    }
                }

                fetchPages(filter)
            }
            setIsFetching(false)
        },
        [params]
    )

    useEffect(() => {
        if (!params && !Cookies.get("facebook_metadata")) {
            navigate("/error")
            return
        }

        const cookies = { ...params }
        delete cookies?.access_token
        delete cookies["22data_access_expiration_time"]
        delete cookies["data_access_expiration_time"]
        delete cookies?.expires_in
        delete cookies?.long_lived_token
        Cookies.set("facebook_metadata", JSON.stringify(cookies))
    }, [params])

    useEffect(() => {
        checkFacebookAccess()
    }, [checkFacebookAccess])

    const handleReturn = (status = "") => {
        const metadata = Cookies.get("facebook_metadata") ? JSON.parse(Cookies.get("facebook_metadata")) : null
        if (metadata) {
            Cookies.remove("facebook_metadata")
            Cookies.remove("google_metadata")
            ResellerService.clearReseller()
            window.location.href = `${metadata?.state?.cb}${
                status === "success" ? "/?auth_type=facebook&status=success" : ""
            }`
        }
    }

    const handleCancel = () => {
        handleReturn()
    }

    const handlePrevious = useCallback(() => {
        if (params && params?.state) {
            const auth_type = params?.state?.auth_type
            if (auth_type === "facebook") {
                openFacebookAccount({
                    companyId: params?.state?.companyId,
                    user_id: params?.state?.user_id,
                    location_id: params?.state?.location_id,
                    cb: params?.state?.cb,
                })
            } else if (auth_type === "instagram") {
                openInstagramAndFacebookAccount({
                    companyId: params?.state?.companyId,
                    user_id: params?.state?.user_id,
                    location_id: params?.state?.location_id,
                    cb: params?.state?.cb,
                })
            }
        }
    }, [params])

    const handleSync = useCallback(async () => {
        setIsLoading(true)
        const auth_type = params?.state?.auth_type ?? "facebook"
        let currentInsta = null
        if (auth_type === "instagram") {
            currentInsta = pages?.length ? pages.filter((item) => item?.ig_id === selectedPage)[0] : null
        }
        let res = null
        if (auth_type === "facebook") {
            res = await FacebookApi.synchronize({
                company_id: params?.state?.companyId,
                location_id: params?.state?.location_id,
                pageId: selectedPage,
            })
        } else {
            if (currentInsta) {
                res = await FacebookApi.syncInstagram({
                    location_id: params?.state?.location_id,
                    ig_id: currentInsta?.ig_id,
                    ig_username: currentInsta?.ig_username,
                    access_id: currentInsta?.access_id,
                })
            }
        }

        setIsLoading(false)
        if (!res?.error) {
            handleReturn("success")
        } else {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
    }, [selectedPage, params])

    return {
        params,
        pages,
        isLoading,
        isFetching,
        selectedPage,
        handleCancel,
        handleSync,
        setSelectedPage,
        handlePrevious,
        handleReturn,
        checkFacebookAccess,
        fetchPages,
    }
}

export default useLogic
