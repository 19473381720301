import CrashAppComponent from "@/components/base/errors/crash-app/inde"
import Error400 from "@/pages/400"
import AuthFacebook from "@/pages/auth_facebook"
import AuthFacebook2 from "@/pages/auth_instagram"
import AuthGooglev2 from "@/pages/auth_multiple_accounts"
import IndexPages from "@/pages/index"
import Redirect from "@/pages/redirect"
import { captureException } from "@sentry/react"
import { useEffect } from "react"
import { RouteObject, useRouteError } from "react-router-dom"
import ProvidersWithOutlet from "./providers-with-outlet"

function SentryRouteErrorFallback() {
    const routeError = useRouteError()

    useEffect(() => {
        captureException(routeError, { level: "fatal" })
    }, [routeError])

    return <CrashAppComponent />
}
const RoutesList: RouteObject[] = [
    {
        path: "/",
        element: <ProvidersWithOutlet />,
        errorElement: <SentryRouteErrorFallback />,
        children: [
            {
                index: true,
                element: <IndexPages />,
            },
            {
                path: "/auth_google",
                element: <AuthGooglev2 />,
            },
            {
                path: "/auth_facebook",
                element: <AuthFacebook />,
            },
            {
                path: "/auth_instagram",
                element: <AuthFacebook2 />,
            },
            {
                path: "/redirect",
                element: <Redirect />,
            },
            {
                path: "*",
                element: <Error400 />,
            },
        ],
    },
]

export default RoutesList
