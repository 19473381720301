import env from "@/config/env"
import Cookies from "js-cookie"
import useUtilsService from "./useBase64"
interface FacebookInput {
    companyId: string
    user_id: string
    location_id: string
    cb: string
}
interface GoogleInput {
    companyId: string
    user_id: string
    cb: string
    access_id?: string
    multiple_accounts?: boolean
    add_account?: boolean
    yext_id?: string
    locationName?: string
    bearer?: string
}
const useSelectAccount = () => {
    const { encodeBase64 } = useUtilsService()

    const objectToQueryString = (obj) => {
        const params = new URLSearchParams()
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                params.append(key, obj[key])
            }
        }
        return params.toString()
    }

    const clearCache = () => {
        Cookies.remove("google_metadata")
        Cookies.remove("facebook_metadata")
        Cookies.remove("gmb_access_id")
    }

    const openGoogleAccount = ({
        companyId,
        user_id,
        cb,
        access_id = "",
        multiple_accounts = false,
        add_account = false,
        yext_id = "",
        locationName = "",
        bearer = "",
    }: GoogleInput) => {
        const params = [
            `companyId=${encodeURIComponent(companyId)}`,
            `user_id=${encodeURIComponent(user_id)}`,
            `cb=${cb}`,
            `auth_type=google`,
            access_id && `gmb_access_id=${encodeURIComponent(access_id)}`,
            yext_id && `yext_id=${encodeURIComponent(yext_id)}`,
            locationName && `locationName=${encodeURIComponent(locationName)}`,
            multiple_accounts && `multiple_accounts=1`,
            bearer && `bearer=${encodeURIComponent(bearer)}`,
            add_account && `add_account=1`,
        ]
            .filter(Boolean)
            .join("&")

        const b64 = encodeBase64(params)

        const query = objectToQueryString({
            client_id: env.GMB_CLIENT_ID,
            redirect_uri: env.APP_OAUTH_REDIRECT_URL,
            response_type: "code",
            scope: env.SCOPE_GOOGLE_BUSINESS,
            prompt: "consent",
            access_type: "offline",
            gsiwebsdk: "3",
            flowName: "GeneralOAuthFlow",
            state: b64,
        })

        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?${query}`

        clearCache()

        window.location.href = authUrl
    }

    const openFacebookAccount = ({ companyId, user_id, location_id, cb }: FacebookInput) => {
        const b64 = encodeBase64(
            `companyId=${companyId}&user_id=${user_id}&location_id=${location_id}&cb=${cb}&auth_type=facebook`
        )
        const query = objectToQueryString({
            client_id: env.FACEBOOK_APP_ID,
            redirect_uri: `${env.APP_OAUTH_REDIRECT_URL}`,
            response_type: "token",
            scope: env.FACEBOOK_SCOPE,
            state: `${b64}`,
        })
        clearCache()
        window.location.href = "https://www.facebook.com/v18.0/dialog/oauth?" + query
    }

    const openInstagramAndFacebookAccount = ({ companyId, user_id, location_id, cb }) => {
        const b64 = encodeBase64(
            `companyId=${companyId}&user_id=${user_id}&location_id=${location_id}&cb=${cb}&auth_type=instagram`
        )

        const query = objectToQueryString({
            client_id: env.FACEBOOK_APP_ID,
            redirect_uri: `${env.APP_OAUTH_REDIRECT_URL}`,
            response_type: "token",
            scope: `${env.INSTAGRAM_SCOPE},`,
            state: `${b64}`,
        })

        window.location.href = "https://www.facebook.com/v18.0/dialog/oauth?" + query
    }

    return {
        openGoogleAccount,
        openFacebookAccount,
        clearCache,
        openInstagramAndFacebookAccount,
    }
}

export default useSelectAccount
